



































































































import { Component, Vue, Ref } from 'vue-property-decorator'
import { CadastroContaUseCase, LoginUseCase } from '@/usecases'
import { Cadastro, SignUpResponse, ResponseStatus } from '@/models'
import { obrigatorio } from '@/shareds/regras-de-form'
import VueRecaptcha from 'vue-recaptcha'
import SeletorDeCadastro from '@/views/landing-page/SeletorDeCadastro.vue'
import CampoDeTelefone from '@/components/ui/CampoDeTelefone.vue'
import kebabCase from "lodash.kebabcase";
import { remove as removeDiacritics } from "diacritics"
import { ambiente } from '@/shareds/utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import AppAlert from '@/components/layout/AppAlert.vue'

@Component({
	components: {
		SeletorDeCadastro, 
		CampoDeTelefone,
		VueRecaptcha,
		AppAlert,
	},
})
export default class SignUp extends Vue {
	@Ref() campoDeNome!: HTMLInputElement
	@Ref() formDeCadastro!: HTMLFormElement
	
	email = ''
	senha = ''
	nome = ''
	subdominio: string | null = null
	tokenRecaptcha: string | null = null
	comoConheceu = ''
	telefone = ''
	logo = require('@/assets/almode_256x181.png')
	dialog = false
	cadastrando = false

	cadastroContaUseCase = new CadastroContaUseCase()
	loginUseCase = new LoginUseCase()

	obrigatorio = obrigatorio

	verificarCaptcha(token: string) {
		this.tokenRecaptcha = token
	}

	mounted() {
		setTimeout(() => {
			this.formDeCadastro.resetValidation()
			this.campoDeNome.focus()
		})
	}

	async cadastrar() {
		if (!this.formDeCadastro.validate()) return
		/*if (!this.tokenRecaptcha) {
			AlertModule.setError('Captcha inválido!')
			return
		}*/
		const cadastro: Cadastro = {
			email: this.email,
			senha: this.senha,
			nome: this.nome,
			subdominio: this.sugestaoDeSubdominio,
			isDemonstracao: false,
			comoConheceu: this.comoConheceu || null,
			telefones: [
				this.telefone,
			].filter(telefone => !!telefone),
			tokenRecaptcha: '',
			ambiente,
		}
		try {
			this.cadastrando = true
			const signUp: SignUpResponse = await this.cadastroContaUseCase.signUpAccount(cadastro)
			if (signUp.status !== ResponseStatus.SUCCESS) {
				throw new Error(signUp.erro)
			}

			AlertModule.setInfo('Verifique seu e-mail para completar seu cadastro')
			window.location.href = `${location.protocol}//${this.sugestaoDeSubdominio}.${location.host}`
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.cadastrando = false
		}
	}

	get sugestaoDeSubdominio() {
		if (this.subdominio !== null) return this.subdominio
		return removeDiacritics(kebabCase(this.nome))
	}

	get sufixoDoSite() {
		return "." + location.host
	}
}
